/* eslint-disable */
import { ENDPOINT_RFQ_QUOTATION_ITEM_LIST, ENDPOINT_RFQ_DETAILS, ENDPOINT_RFQ, ENDPOINT_RFQ_ACTION_SUBMIT } from '@/core/constant/menuEndpoint'
import { floatParsing } from '@/core/components/helpers/index.js'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export const Store = {
	namespaced: true,

	// nama module, untuk call di component menggunakan "example/<nama_method>"
	name: 'rfq',

	state: {
    quotationInformation: {
      isNewProject: true,
      projectId: "",
      projectName: "", 
      vendorId: "", 
      vendorName: "", 
      vendorEmail: "",
      isNewVendor: true, 
      tahun: "", 
      budgetSpent: "", 
      kodeWbsLev1: "", 
      judulRfq: "", 
      notes: "",
      directApproverId: "", 
      directApproverName: "",
      isDeleted: false,
      disabled: true,
      filterVendorId: '',
      filterLocalPartnerVendorId: '',
    },
    rfqItemLists: [
      {
        rfqItemListId: null,
        rfqId: null, 
        jenis: "", 
        productLayanan: "", 
        subProduct: "", 
        description: "", 
        lokasi: "", 
        qty: 1,
        uom: ""
      },
      {
        rfqItemListId: null,
        rfqId: null, 
        jenis: "", 
        productLayanan: "", 
        subProduct: "", 
        description: "", 
        lokasi: "", 
        qty: 1, 
        uom: ""
      }, 
      {
        rfqItemListId: null,
        rfqId: null, 
        jenis: "", 
        productLayanan: "", 
        subProduct: "", 
        description: "", 
        lokasi: "", 
        qty: 1, 
        uom: ""
      }, 
    ],
    rfqMgr: {
      userName: '',
      userId: '',
    },
    listProjectId: [],
    collapse: {
      quotationInformation: true,
      itemList: true,
    },
    rfqProjectList: [],
    rfqVendorList: [],
    rfqLocalPartnerList: [],
	},

	getters: {},

	mutations: {
		SET_QUOTATION_INFORMATION(state, payload) {
      state.quotationInformation = payload
    },
    SET_RFQ_ITEM_LISTS(state, payload) {
      state.rfqItemLists = payload
    },
    SET_RFQ_MGR(state, payload) {
      state.rfqMgr = payload
    },
    SET_LIST_PROJECT_ID(state, payload) {
      state.listProjectId = payload
    },
    SET_COLLAPSE(state, payload) {
      state.collapse = payload
    },
    SET_RFQ_PROJECT_LIST(state, payload) {
      state.rfqProjectList = payload
    },
    SET_RFQ_VENDOR_LIST(state, payload) {
      state.rfqVendorList = payload
    },
    SET_RFQ_LOCAL_PARTNER_LIST(state, payload) {
      state.rfqLocalPartnerList = payload
    }
	},

	actions: {

    //Get API
    async getQuotationInformation({ commit, state, dispatch }, payload) {
      try {
        const rfqId = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_DETAILS}/${rfqId}`)
        console.log(response, 'response get quotation information');
        const result = response.data.result
        const dataCommit = { ...result, disabled: true, budgetSpent: formatThousandSeparate(result.budgetSpent?.toString())}
        commit('SET_QUOTATION_INFORMATION', dataCommit)
      } catch (error) {
        console.log(error);
        await dispatch('modal/commitErrorModal', { response: error.response }, { root: true })
      }
    },
    async getQuotationItemList({ commit, state }, payload) {
      try {
        const rfqId = payload
        const response = await this.$api.get(`${ENDPOINT_RFQ_QUOTATION_ITEM_LIST}/${rfqId}`)
        console.log(response, 'response get quotation item list');
        if (response.data.result.length > 0) {
          commit('SET_RFQ_ITEM_LISTS', response.data.result)
        } else {
          commit('SET_RFQ_ITEM_LISTS', [
            {
              rfqItemListId: null,
              rfqId: null, 
              jenis: "", 
              productLayanan: "", 
              subProduct: "", 
              description: "", 
              lokasi: "", 
              qty: 1, 
              uom: ""
            }]
          )
        }
      } catch (error) {
        console.log(error);
        commit('SET_RFQ_ITEM_LISTS', [
          {
            rfqItemListId: null,
            rfqId: null, 
            jenis: "", 
            productLayanan: "", 
            subProduct: "", 
            description: "", 
            lokasi: "", 
            qty: 1, 
            uom: ""
          }]
        )

      }
    },
    async getQuotationInformationProjectId({ commit, state }, payload) {
      try {
        const { projectId } = payload
        const response = await this.$api.get(`dashboard/rfx/completed`, { projectId })
        console.log(response, 'response search project Id');
        commit('SET_LIST_PROJECT_ID', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getQuotationProjectList({ commit, state, }, payload) {
      try {
        const { query } = payload
        const response = await this.$api.get(`rfq/project/list`, { query })
        console.log(response, 'response project list rfq');
        commit('SET_RFQ_PROJECT_LIST', response.data.result)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async getQuotationProjectPrepare({ commit, state, dispatch }, payload) {
      try {
        const { projectId, search } = payload
        await commit('SET_RFQ_VENDOR_LIST', [])
        const response = await this.$api.get('rfq/project/prepare', { projectId, search })
        const vendors = response.data.result.vendors;
        await commit('SET_RFQ_VENDOR_LIST', vendors)
        return response
      } catch (error) {
        return error.response
      }
    },
    async getQuotationProjectPrepareLocalPartner({ commit, state, dispatch }, payload) {
      try {
        const { projectId, principalVendorId, localPartnerVendorId, search } = payload
        await commit('SET_RFQ_LOCAL_PARTNER_LIST', [])
        const response = await this.$api.get('rfq/project/prepare', { projectId, principalVendorId, localPartnerVendorId, search })
        const localPartnerOptions = response.data.result?.vendors?.[0].localPartners
        await commit('SET_RFQ_LOCAL_PARTNER_LIST', localPartnerOptions)
        return response
      } catch (error) {
        return error.response
        
      }
    },
    //for get direct approver mgr default
    async getRfqMgr({ commit, }, payload) {
      try {
        const response = await this.$api.get('rfq/get-mgr')
        console.log(response, 'get rfq mgr');
        commit('SET_RFQ_MGR', response.data)
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    rfqItemListFilter({ commit, state,}, payload) {
      return state.rfqItemLists.filter(e => {
        if (e.jenis || e.productLayanan || e.subProduct || e.description || e.lokasi || e.uom) {
          return e
        }
      })
    },

    rfqItemListFilterSubmit({ commit, state,}, payload) {
      return state.rfqItemLists.filter(e => {
        if (e.jenis && e.productLayanan && e.qty && e.uom) {
          return e
        }
      })
    },

    async postSaveAsDraft({ commit, state, dispatch}, payload) {
      try {
        //filter rfqItemList empty
        const rfqItemLists = await dispatch('rfqItemListFilter')
        const dataPost = {
          isNewProject: state.quotationInformation.isNewProject, 
          projectId: state.quotationInformation.projectId || null,
          projectName: state.quotationInformation.projectName, 
          vendorId: state.quotationInformation.vendorId, 
          vendorName: state.quotationInformation.vendorName, 
          vendorEmail: state.quotationInformation.vendorEmail,
          isNewVendor: state.quotationInformation.isNewVendor, 
          tahun: state.quotationInformation.tahun, 
          budgetSpent: state.quotationInformation.budgetSpent.split(',').join(''), 
          kodeWbsLev1: state.quotationInformation.kodeWbsLev1, 
          judulRfq: state.quotationInformation.judulRfq, 
          notes: state.quotationInformation.notes,
          directApproverId: state.quotationInformation.directApproverId, 
          directApproverName: state.quotationInformation.directApproverName,
          filterVendorId: state.quotationInformation.filterVendorId,
          filterLocalPartnerVendorId: state.quotationInformation.filterLocalPartnerVendorId,
          rfqItemLists: rfqItemLists?.map(e => ({ ...e, qty: floatParsing(e.qty) })),
        }
        console.log(dataPost, 'payload post save as draft');
        const response = await this.$api.post(ENDPOINT_RFQ, dataPost)
        console.log(response, 'post save as draft');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async postSubmit({ commit, state, dispatch }, payload) {
      try {
        //filter rfqItemList empty
        const rfqItemLists = await dispatch('rfqItemListFilterSubmit')
        const dataPost = {
          isNewProject: state.quotationInformation.isNewProject, 
          projectId: state.quotationInformation.projectId || null,
          projectName: state.quotationInformation.projectName, 
          vendorId: state.quotationInformation.vendorId, 
          vendorName: state.quotationInformation.vendorName, 
          vendorEmail: state.quotationInformation.vendorEmail,
          isNewVendor: state.quotationInformation.isNewVendor, 
          tahun: state.quotationInformation.tahun, 
          budgetSpent: state.quotationInformation.budgetSpent.split(',').join(''), 
          kodeWbsLev1: state.quotationInformation.kodeWbsLev1, 
          judulRfq: state.quotationInformation.judulRfq, 
          notes: state.quotationInformation.notes,
          directApproverId: state.quotationInformation.directApproverId, 
          directApproverName: state.quotationInformation.directApproverName,
          filterVendorId: state.quotationInformation.filterVendorId,
          filterLocalPartnerVendorId: state.quotationInformation.filterLocalPartnerVendorId,
          rfqItemLists: rfqItemLists?.map(e => ({ ...e, qty: floatParsing(e.qty)})),
        }
        console.log(dataPost, 'payload post submit');
        const response = await this.$api.post(ENDPOINT_RFQ_ACTION_SUBMIT, dataPost)
        console.log(response, 'post submit');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async putSaveAsDraft({ commit, state, dispatch}, payload) {
      try {
        //filter rfqItemList empty
        const rfqItemLists = await dispatch('rfqItemListFilter')
        const rfqId = payload
        const dataPost = {
          isNewProject: state.quotationInformation.isNewProject, 
          projectId: state.quotationInformation.projectId || null,
          projectName: state.quotationInformation.projectName, 
          vendorId: state.quotationInformation.vendorId, 
          vendorName: state.quotationInformation.vendorName, 
          vendorEmail: state.quotationInformation.vendorEmail,
          isNewVendor: state.quotationInformation.isNewVendor, 
          tahun: state.quotationInformation.tahun, 
          budgetSpent: state.quotationInformation.budgetSpent.split(',').join(''), 
          kodeWbsLev1: state.quotationInformation.kodeWbsLev1, 
          judulRfq: state.quotationInformation.judulRfq, 
          notes: state.quotationInformation.notes,
          directApproverId: state.quotationInformation.directApproverId, 
          directApproverName: state.quotationInformation.directApproverName,
          filterVendorId: state.quotationInformation.filterVendorId,
          filterLocalPartnerVendorId: state.quotationInformation.filterLocalPartnerVendorId,
          rfqItemLists: rfqItemLists?.map(e => ({ ...e, qty: floatParsing(e.qty) }))
        }
        console.log(dataPost, 'payload put save as draft');
        const response = await this.$api.put(`${ENDPOINT_RFQ}/${rfqId}`, dataPost)
        console.log(response, 'post save as draft');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    async putSubmit({ commit, state, dispatch }, payload) {
      try {
        //filter rfqItemList empty
        const rfqItemLists = await dispatch('rfqItemListFilterSubmit')
        const rfqId = payload
        const dataPost = {
          isNewProject: state.quotationInformation.isNewProject, 
          projectId: state.quotationInformation.projectId || null,
          projectName: state.quotationInformation.projectName, 
          vendorId: state.quotationInformation.vendorId, 
          vendorName: state.quotationInformation.vendorName, 
          vendorEmail: state.quotationInformation.vendorEmail,
          isNewVendor: state.quotationInformation.isNewVendor, 
          tahun: state.quotationInformation.tahun, 
          budgetSpent: state.quotationInformation.budgetSpent.split(',').join(''), 
          kodeWbsLev1: state.quotationInformation.kodeWbsLev1, 
          judulRfq: state.quotationInformation.judulRfq, 
          notes: state.quotationInformation.notes,
          directApproverId: state.quotationInformation.directApproverId, 
          directApproverName: state.quotationInformation.directApproverName,
          filterVendorId: state.quotationInformation.filterVendorId,
          filterLocalPartnerVendorId: state.quotationInformation.filterLocalPartnerVendorId,
          rfqItemLists: rfqItemLists?.map(e => ({ ...e, qty: floatParsing(e.qty) }))
        }
        console.log(dataPost, 'payload put submit');
        const response = await this.$api.put(`rfq/${rfqId}/submit`, dataPost)
        console.log(response, 'post save as draft');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //reset state
    resetState({ commit, state, dispatch }) {
      dispatch('resetStateQuotationInformation')
      dispatch('resetStateRfqItemLists')
    },
    resetStateQuotationInformation({ commit, state,} ) {
      const quotationInformation = {
        isNewProject: true,
        projectId: "",
        projectName: "", 
        vendorId: "", 
        vendorName: "", 
        isNewVendor: false, 
        tahun: "", 
        budgetSpent: "", 
        kodeWbsLev1: "", 
        judulRfq: "", 
        notes: "",
        directApproverId: "", 
        directApproverName: "",
        disabled: true,
      }
      commit('SET_QUOTATION_INFORMATION', quotationInformation)
    },
    resetStateRfqItemLists({ commit, state, }) {
      const rfqItemLists = [
        {
          rfqItemListId: null,
          rfqId: null, 
          jenis: "", 
          productLayanan: "", 
          subProduct: "", 
          description: "", 
          lokasi: "", 
          qty: 1, 
          uom: ""
        },
        {
          rfqItemListId: null,
          rfqId: null, 
          jenis: "", 
          productLayanan: "", 
          subProduct: "", 
          description: "", 
          lokasi: "", 
          qty: 1, 
          uom: ""
        }, 
        {
          rfqItemListId: null,
          rfqId: null, 
          jenis: "", 
          productLayanan: "", 
          subProduct: "", 
          description: "", 
          lokasi: "", 
          qty: 1, 
          uom: ""
        }, 
      ]
      commit('SET_RFQ_ITEM_LISTS', rfqItemLists)
    },
		

	}
}