/* eslint-disable */
import { ENDPOINT_RFP_DELETE } from '@/core/constant/menuEndpoint'

export const Store = {
  namespaced: true,
  name: 'deleteRfp',
  state: {
    
  },
  gettters: {

  },
  mutations: {

  },
  actions: {

    //delete rfp
    async deleteRFP({ commit, state }, payload) {
      try {
        const rfpId = payload;
        const response = await this.$api.delete(`${ENDPOINT_RFP_DELETE}/${rfpId}`)
        console.log(response, 'response put delete rfp');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    }

  }
  
}