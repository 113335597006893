/* eslint-disable */
//experimental to divide store rfp
import { ENDPOINT_RFQ_DELETE } from '@/core/constant/menuEndpoint'
export const Store = {
  namespaced: true,
  name: 'deleteRfq',
  state: {
    
  },
  gettters: {

  },
  mutations: {

  },
  actions: {

    //delete rfp
    async deleteRFQ({ commit, state }, payload) {
      try {
        const rfqId = payload;
        const response = await this.$api.delete(`${ENDPOINT_RFQ_DELETE}/${rfqId}`)
        console.log(response, 'response put delete rfq');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

  }
  
}