/* eslint-disable */
import { ENDPOINT_DETAILS_REJECT, ENDPOINT_DETAILS_APPROVE, ENDPOINT_DETAILS_CANCEL } from '@/core/constant/menuEndpoint'
export const Store = {
  namespaced: true,
  name: 'approval',
  state: {
  },
  mutations: {
  },
  actions: {
    async putProposalInformationReject({commit, state}, payload) {
      try {
        const { rfpId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_DETAILS_REJECT}/${rfpId}`, { reason })
        console.log(response, 'response proposal information reject');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putProposalInformationApprove({commit, state}, payload) {
      try {
        const { rfpId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_DETAILS_APPROVE}/${rfpId}`, { reason } )
        console.log(response, 'response proposal information approve');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    async putProposalInformationCancel({commit, state}, payload) {
      try {
        const { rfpId, reason } = payload
        const response = await this.$api.put(`${ENDPOINT_DETAILS_CANCEL}/${rfpId}`, { reason })
        console.log(response, 'response proposal information cancel')
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    }
  }
  
}