import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, size, mimes, max, min_value, max_value, numeric } from 'vee-validate/dist/rules';
import { floatParsing } from "@/core/components/helpers";
extend('required', {
  ...required,
  message: 'Wajib Diisi'
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'Kolom {_field_} Maksimal {length} Karakter'
})

extend('maxDigit', {
  ...max,
  params: ['length'],
  message: 'Maksimal {length} Digit'
})

extend('maxBudgetSpent', {
  validate(value) {
    return +value.split(',').join('') <= 999999999999
  },
  message: 'Maksimal 999,999,999,999'
})

extend('email', {
  ...email,
  message: 'Email Tidak Valid'
})

extend('size', {
  ...size,
  message: 'Size File Tidak Didukung'
})

extend('mimes', {
  ...mimes,
  message: 'Format File Tidak Didukung'
})

extend('numeric', {
  ...numeric,
  message: 'Harus Nomor'
})

extend('min_value', {
  ...min_value,
  message: 'Harus Nomor'
})

extend('min_one', {
  ...min_value,
  message: 'Minimal 1'
})

extend('min_one_with_decimal', {
  validate(value) {
    const number = floatParsing(value)
    return number >= 1
  },
  message: 'Minimal 1'
})

extend('decimalTwoDigits', {
  validate(value) {
    const regexTestDecimalTwoDigits = /^\d*(\.\d{0,2})?$/
    return regexTestDecimalTwoDigits.test(value)
  },
  message: 'Decimal 2'
})

extend('max_value', {
  ...max_value,
  message: 'Tidak boleh melebihi nilai bobot'
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)