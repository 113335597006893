/* eslint-disable */
//experimental to divide store rfp
import { ENDPOINT_RFP_ACTION_SUBMIT, ENDPOINT_RFP } from "@/core/constant/menuEndpoint"
export const Store = {
  namespaced: true,
  name: 'rfpSubmit',
  state: {
    
  },
  gettters: {

  },
  mutations: {
    
  },
  actions: {
    vendorCandidate({commit, state, rootState, dispatch}, payload) {
      return rootState.rfp.vendorCandidate.filter(e => e.vendorName).map(e => {
        return {
          rfpVendorCandidateId: e.rfpVendorCandidateId,
          isNewVendor: e.isNewVendor,
          vendorName: e.vendorName,
          vendorEmail: e.vendorEmail,
          reason: e.reason,
          vendorId: e.vendorId,
          localPartners: e.localPartners.filter(el => el.vendorName)
        }
      })
    },
    vendorCandidatePut({commit, state, rootState, dispatch}, payload) {
      return rootState.rfp.vendorCandidate.filter(e => e.vendorName).map(e => {
        return {
          rfpVendorCandidateId: e.rfpVendorCandidateId,
          vendorId: e.vendorId,
          isNewVendor: e.isNewVendor,
          vendorName: e.vendorName,
          vendorEmail: e.vendorEmail,
          reason: e.reason,
          isApprove: e.isApprove,
          isPerluPoc: e.isPerluPoc,
          isActive: e.isActive,
          isDeleted: e.isDeleted,
          localPartners: e.localPartners.filter(el => el.vendorName),
        }
      })
    },
    rfpScoringTeknisList({ commit, state, rootState } ,payload) {
      let rfpScoringTeknisList = []
      rootState.rfp.scoringTeknisCriteria.forEach(e => {
        let rfpScoringTeknis = [] 
        e.rfpScoringTeknis.forEach(el => {
          if (el.name) {
            rfpScoringTeknis.push({ ...el, bobot: +el.bobot })
          }
        })
        rfpScoringTeknisList.push({ ...e, bobot: +e.bobot, rfpScoringTeknis: rfpScoringTeknis })
      })
      return rfpScoringTeknisList
    },
    checkers({ commit, state, rootState }, payload) {
      let checkers = []
      if (rootState.rfp.proposalInformation.reviewerGsitId) {
        checkers.push({ userId: rootState.rfp.proposalInformation.reviewerGsitId, typeApproval: 'REVIEWER_GSIT' })
      }
      if (rootState.rfp.proposalInformation.reviewerUkkpId) {
        checkers.push({ userId: rootState.rfp.proposalInformation.reviewerUkkpId, typeApproval: 'REVIEWER_UKKP' })
      }
      if (rootState.rfp.proposalInformation.firstApproverDlogId) {
        checkers.push({ userId: rootState.rfp.proposalInformation.firstApproverDlogId, typeApproval: 'APPROVER_DLOG_1' })
      }
      if (rootState.rfp.proposalInformation.secondApproverDlogId) {
        checkers.push({ userId: rootState.rfp.proposalInformation.secondApproverDlogId, typeApproval: 'APPROVER_DLOG_2' })
      }
      return checkers
    },    
    //post rfp, hit save as draft from create new project
    async postSaveAsDraft({commit, state, rootState, dispatch}, payload) {
      try {
        const vendorCandidate = await dispatch('vendorCandidate')
        let rfpScoringTeknisList = await dispatch('rfpScoringTeknisList')
        let checkers = await dispatch('checkers')
        const data = {
          proposalInformation: {
            adaUkkp: rootState.rfp.proposalInformation.adaUkkp,
            unitKerja: rootState.rfp.proposalInformation.unitKerja,
            approverUkkpId: rootState.rfp.proposalInformation.approverUkkpId,
            approverUkkpName: rootState.rfp.proposalInformation.approverUkkpName,
            directApproverId: rootState.rfp.proposalInformation.directApproverId,
            directApproverName: rootState.rfp.proposalInformation.directApproverName,
            checkers: checkers,
            projectName: rootState.rfp.proposalInformation.projectName,
            technicalCoordinatorId: rootState.rfp.proposalInformation.technicalCoordinatorId,
            technicalCoordinatorName: rootState.rfp.proposalInformation.technicalCoordinatorName,
            technicalCoordinatorEmail: rootState.rfp.proposalInformation.technicalCoordinatorEmail,
            technicalCoordinatorMobilePhone: rootState.rfp.proposalInformation.technicalCoordinatorMobilePhone,
            technicalCoordinatorOfficePhone: rootState.rfp.proposalInformation.technicalCoordinatorOfficePhone,
            ext: rootState.rfp.proposalInformation.ext,
            budgetSpent: rootState.rfp.proposalInformation.budgetSpent.split(',').join(''),
            kodeWbsLev1: rootState.rfp.proposalInformation.kodeWbsLev1,
            isSoftware: rootState.rfp.proposalInformation.isSoftware,
            notes: rootState.rfp.proposalInformation.notes,
            isDeleted: rootState.rfp.proposalInformation.isDeleted,
          },
          projectInformation: {
            background: rootState.rfp.projectInformation.background,
            tujuanProyek: rootState.rfp.projectInformation.tujuanProyek,
            jangkaWaktuProyek: rootState.rfp.projectInformation.jangkaWaktuProyek,
            rfpId: rootState.rfp.projectInformation.rfpId,
          },
          projectRequirement: {
            acceptanceTest: rootState.rfp.projectRequirement.acceptanceTest,
            arsitekturDanStandar: rootState.rfp.projectRequirement.arsitekturDanStandar,
            dokumentasi: rootState.rfp.projectRequirement.dokumentasi,
            dukunganPemeliharaan: rootState.rfp.projectRequirement.dukunganPemeliharaan,
            implementasi: rootState.rfp.projectRequirement.implementasi,
            pelatihan: rootState.rfp.projectRequirement.pelatihan,
            pengamanTi: rootState.rfp.projectRequirement.pengamanTi,
            pengembanganDanInterface: rootState.rfp.projectRequirement.pengembanganDanInterface,
            persyaratanProdukDanJasa: rootState.rfp.projectRequirement.persyaratanProdukDanJasa,
            persyaratanTeknis: rootState.rfp.projectRequirement.persyaratanTeknis,
            rfpAttachments: rootState.rfp.projectRequirement.rfpAttachments,
            rfpId: rootState.rfp.projectRequirement.rfpId,
            projectStatusReporting: rootState.rfp.projectRequirement.projectStatusReporting,
            referensi: rootState.rfp.projectRequirement.referensi,
            serviceLevel: rootState.rfp.projectRequirement.serviceLevel,
            sumberDayaYangTersedia: rootState.rfp.projectRequirement.sumberDayaYangTersedia,
          },
          vendorCandidates: vendorCandidate,
          scoringTeknises: rfpScoringTeknisList,
        }
        console.log(data, 'payload data post save as draft');
        const response = await this.$api.post(ENDPOINT_RFP, data)
        console.log(response, 'response post save as draft');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },
    //post submit rfp, hit submit from create new project
    async postSubmitProject({ commit, state, rootState, dispatch}, payload) {
      try {
        const vendorCandidate = await dispatch('vendorCandidate')
        let rfpScoringTeknisList = await dispatch('rfpScoringTeknisList')
        let checkers = await dispatch('checkers')
        const data = {
          proposalInformation: {
            adaUkkp: rootState.rfp.proposalInformation.adaUkkp,
            unitKerja: rootState.rfp.proposalInformation.unitKerja,
            approverUkkpId: rootState.rfp.proposalInformation.approverUkkpId,
            approverUkkpName: rootState.rfp.proposalInformation.approverUkkpName,
            directApproverId: rootState.rfp.proposalInformation.directApproverId,
            directApproverName: rootState.rfp.proposalInformation.directApproverName,
            checkers: checkers,
            projectName: rootState.rfp.proposalInformation.projectName,
            technicalCoordinatorId: rootState.rfp.proposalInformation.technicalCoordinatorId,
            technicalCoordinatorName: rootState.rfp.proposalInformation.technicalCoordinatorName,
            technicalCoordinatorEmail: rootState.rfp.proposalInformation.technicalCoordinatorEmail,
            technicalCoordinatorMobilePhone: rootState.rfp.proposalInformation.technicalCoordinatorMobilePhone,
            technicalCoordinatorOfficePhone: rootState.rfp.proposalInformation.technicalCoordinatorOfficePhone,
            ext: rootState.rfp.proposalInformation.ext,
            budgetSpent: rootState.rfp.proposalInformation.budgetSpent.split(',').join(''),
            kodeWbsLev1: rootState.rfp.proposalInformation.kodeWbsLev1,
            isSoftware: rootState.rfp.proposalInformation.isSoftware,
            notes: rootState.rfp.proposalInformation.notes,
            isDeleted: rootState.rfp.proposalInformation.isDeleted,
          },
          projectInformation: {
            background: rootState.rfp.projectInformation.background,
            tujuanProyek: rootState.rfp.projectInformation.tujuanProyek,
            jangkaWaktuProyek: rootState.rfp.projectInformation.jangkaWaktuProyek,
            rfpId: rootState.rfp.projectInformation.rfpId,
          },
          projectRequirement: {
            acceptanceTest: rootState.rfp.projectRequirement.acceptanceTest,
            arsitekturDanStandar: rootState.rfp.projectRequirement.arsitekturDanStandar,
            dokumentasi: rootState.rfp.projectRequirement.dokumentasi,
            dukunganPemeliharaan: rootState.rfp.projectRequirement.dukunganPemeliharaan,
            implementasi: rootState.rfp.projectRequirement.implementasi,
            pelatihan: rootState.rfp.projectRequirement.pelatihan,
            pengamanTi: rootState.rfp.projectRequirement.pengamanTi,
            pengembanganDanInterface: rootState.rfp.projectRequirement.pengembanganDanInterface,
            persyaratanProdukDanJasa: rootState.rfp.projectRequirement.persyaratanProdukDanJasa,
            persyaratanTeknis: rootState.rfp.projectRequirement.persyaratanTeknis,
            rfpAttachments: rootState.rfp.projectRequirement.rfpAttachments,
            rfpId: rootState.rfp.projectRequirement.rfpId,
            projectStatusReporting: rootState.rfp.projectRequirement.projectStatusReporting,
            referensi: rootState.rfp.projectRequirement.referensi,
            serviceLevel: rootState.rfp.projectRequirement.serviceLevel,
            sumberDayaYangTersedia: rootState.rfp.projectRequirement.sumberDayaYangTersedia,
          },
          vendorCandidates: vendorCandidate,
          scoringTeknises: rfpScoringTeknisList,
        }
        console.log(data, 'payload data post submit');
        const response = await this.$api.post(ENDPOINT_RFP_ACTION_SUBMIT, data)
        console.log(response, 'response post submit project');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //put rfp, hit save as draft from continue draft
    async putSaveAsDraft({ commit, state, rootState, dispatch }, payload) {
      try {
        const rfpId = payload
        const vendorCandidate = await dispatch('vendorCandidatePut')
        let rfpScoringTeknisList = await dispatch('rfpScoringTeknisList')
        let checkers = await dispatch('checkers')
        const data = {
          rfpId: rfpId,
          proposalInformation: {
            adaUkkp: rootState.rfp.proposalInformation.adaUkkp,
            unitKerja: rootState.rfp.proposalInformation.unitKerja,
            approverUkkpId: rootState.rfp.proposalInformation.approverUkkpId,
            approverUkkpName: rootState.rfp.proposalInformation.approverUkkpName,
            directApproverId: rootState.rfp.proposalInformation.directApproverId,
            directApproverName: rootState.rfp.proposalInformation.directApproverName,
            checkers: checkers,
            projectName: rootState.rfp.proposalInformation.projectName,
            technicalCoordinatorId: rootState.rfp.proposalInformation.technicalCoordinatorId,
            technicalCoordinatorName: rootState.rfp.proposalInformation.technicalCoordinatorName,
            technicalCoordinatorEmail: rootState.rfp.proposalInformation.technicalCoordinatorEmail,
            technicalCoordinatorMobilePhone: rootState.rfp.proposalInformation.technicalCoordinatorMobilePhone,
            technicalCoordinatorOfficePhone: rootState.rfp.proposalInformation.technicalCoordinatorOfficePhone,
            ext: rootState.rfp.proposalInformation.ext,
            budgetSpent: rootState.rfp.proposalInformation.budgetSpent.split(',').join(''),
            kodeWbsLev1: rootState.rfp.proposalInformation.kodeWbsLev1,
            isSoftware: rootState.rfp.proposalInformation.isSoftware,
            notes: rootState.rfp.proposalInformation.notes,
            isDeleted: rootState.rfp.proposalInformation.isDeleted,
          },
          projectInformation: {
            background: rootState.rfp.projectInformation.background,
            tujuanProyek: rootState.rfp.projectInformation.tujuanProyek,
            jangkaWaktuProyek: rootState.rfp.projectInformation.jangkaWaktuProyek,
            rfpId: rfpId,
          },
          projectRequirement: {
            acceptanceTest: rootState.rfp.projectRequirement.acceptanceTest,
            arsitekturDanStandar: rootState.rfp.projectRequirement.arsitekturDanStandar,
            dokumentasi: rootState.rfp.projectRequirement.dokumentasi,
            dukunganPemeliharaan: rootState.rfp.projectRequirement.dukunganPemeliharaan,
            implementasi: rootState.rfp.projectRequirement.implementasi,
            pelatihan: rootState.rfp.projectRequirement.pelatihan,
            pengamanTi: rootState.rfp.projectRequirement.pengamanTi,
            pengembanganDanInterface: rootState.rfp.projectRequirement.pengembanganDanInterface,
            persyaratanProdukDanJasa: rootState.rfp.projectRequirement.persyaratanProdukDanJasa,
            persyaratanTeknis: rootState.rfp.projectRequirement.persyaratanTeknis,
            rfpAttachments: rootState.rfp.projectRequirement.rfpAttachments,
            rfpId: rfpId,
            projectStatusReporting: rootState.rfp.projectRequirement.projectStatusReporting,
            referensi: rootState.rfp.projectRequirement.referensi,
            serviceLevel: rootState.rfp.projectRequirement.serviceLevel,
            sumberDayaYangTersedia: rootState.rfp.projectRequirement.sumberDayaYangTersedia,
          },
          vendorCandidates: vendorCandidate,
          scoringTeknises: rfpScoringTeknisList,
        }
        console.log(data, 'payload data put save as draft');
        const response = await this.$api.put(ENDPOINT_RFP, data)
        console.log(response, 'response put save as draft');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

    //put submit, hit submit from continue draft
    async putSubmitProject({ commit, state, rootState, dispatch }, payload) {
      try {
        const rfpId = payload
        const vendorCandidate = await dispatch('vendorCandidatePut')
        let rfpScoringTeknisList = await dispatch('rfpScoringTeknisList')
        let checkers = await dispatch('checkers')
        const data = {
          rfpId: rfpId,
          proposalInformation: {
            adaUkkp: rootState.rfp.proposalInformation.adaUkkp,
            unitKerja: rootState.rfp.proposalInformation.unitKerja,
            approverUkkpId: rootState.rfp.proposalInformation.approverUkkpId,
            approverUkkpName: rootState.rfp.proposalInformation.approverUkkpName,
            directApproverId: rootState.rfp.proposalInformation.directApproverId,
            directApproverName: rootState.rfp.proposalInformation.directApproverName,
            checkers: checkers,
            projectName: rootState.rfp.proposalInformation.projectName,
            technicalCoordinatorId: rootState.rfp.proposalInformation.technicalCoordinatorId,
            technicalCoordinatorName: rootState.rfp.proposalInformation.technicalCoordinatorName,
            technicalCoordinatorEmail: rootState.rfp.proposalInformation.technicalCoordinatorEmail,
            technicalCoordinatorMobilePhone: rootState.rfp.proposalInformation.technicalCoordinatorMobilePhone,
            technicalCoordinatorOfficePhone: rootState.rfp.proposalInformation.technicalCoordinatorOfficePhone,
            ext: rootState.rfp.proposalInformation.ext,
            budgetSpent: rootState.rfp.proposalInformation.budgetSpent.split(',').join(''),
            kodeWbsLev1: rootState.rfp.proposalInformation.kodeWbsLev1,
            isSoftware: rootState.rfp.proposalInformation.isSoftware,
            notes: rootState.rfp.proposalInformation.notes,
            isDeleted: rootState.rfp.proposalInformation.isDeleted,
          },
          projectInformation: {
            background: rootState.rfp.projectInformation.background,
            tujuanProyek: rootState.rfp.projectInformation.tujuanProyek,
            jangkaWaktuProyek: rootState.rfp.projectInformation.jangkaWaktuProyek,
            rfpId: rfpId,
          },
          projectRequirement: {
            acceptanceTest: rootState.rfp.projectRequirement.acceptanceTest,
            arsitekturDanStandar: rootState.rfp.projectRequirement.arsitekturDanStandar,
            dokumentasi: rootState.rfp.projectRequirement.dokumentasi,
            dukunganPemeliharaan: rootState.rfp.projectRequirement.dukunganPemeliharaan,
            implementasi: rootState.rfp.projectRequirement.implementasi,
            pelatihan: rootState.rfp.projectRequirement.pelatihan,
            pengamanTi: rootState.rfp.projectRequirement.pengamanTi,
            pengembanganDanInterface: rootState.rfp.projectRequirement.pengembanganDanInterface,
            persyaratanProdukDanJasa: rootState.rfp.projectRequirement.persyaratanProdukDanJasa,
            persyaratanTeknis: rootState.rfp.projectRequirement.persyaratanTeknis,
            rfpAttachments: rootState.rfp.projectRequirement.rfpAttachments,
            rfpId: rfpId,
            projectStatusReporting: rootState.rfp.projectRequirement.projectStatusReporting,
            referensi: rootState.rfp.projectRequirement.referensi,
            serviceLevel: rootState.rfp.projectRequirement.serviceLevel,
            sumberDayaYangTersedia: rootState.rfp.projectRequirement.sumberDayaYangTersedia,
          },
          vendorCandidates: vendorCandidate,
          scoringTeknises: rfpScoringTeknisList,
        }
        console.log(data, 'payload data put submit');

        const response = await this.$api.put(ENDPOINT_RFP_ACTION_SUBMIT, data)
        console.log(response, 'response put submit project');
        return response
      } catch (error) {
        console.log(error);
        return error.response
      }
    },

  }
  
}